






























import ProfileTile from '@/domains/practitioner/modules/shared/components/ProfileTile.vue';
import { Client, Resource, ResourceTopic } from '@/models';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  components: { ProfileTile },
  name: 'ViewResourceTopicPage',
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const topic = ref<ResourceTopic | null>(null);
    const resources = ref<Resource[]>([]);
    const adding = ref(false);
    const clients = ref<Client[]>([]);
    const { router } = useRouter();

    const headers = [
      {
        text: 'Type',
        value: 'type',
        width: '10%',
      },
      { text: 'Name', value: 'data.name' },
      { text: 'Actions', value: 'actions' },
    ];

    const loadResources = async () => {
      resources.value = (await topic.value?.resources().getAll()) ?? [];
    };

    const loadTopic = async () => {
      topic.value = await ResourceTopic.find(props.uuid);
      clients.value = topic.value.clients;
    };

    onMounted(async () => {
      await loadTopic();
      await loadResources();
    });

    const added = async () => {
      adding.value = false;
      await loadResources();
    };

    const updateClients = async () => {
      topic.value?.updateRaw({
        clients: clients.value.map((client: Client) => client.uuid),
      });
    };

    const goTo = (item: Resource) => {
      router.push({ name: routeNames.patient.viewResource, params: { uuid: item.uuid } });
    };

    return {
      topic,
      resources,
      adding,
      added,
      clients,
      updateClients,
      headers,
      goTo,
    };
  },
});
